import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Routes from './routes/routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <App>
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </App>

);

reportWebVitals();