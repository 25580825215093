import style from './style.module.scss';

function Main() {
  return (
    <div className={style.App}>
      <header className={style.App_Header}>
        <p>
          Aopa! Olá Mundo!
        </p>
      </header>
    </div>
  );
}

export default Main;
